import { createUserTheme, deleteUserTheme, setDefaultTheme, setSelectedTheme, updateUserTheme } from '@services/themes';
import { useQueryClient } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { GET_THEMES_KEY } from './useFetchThemes';
import { type Theme } from '../store/ThemeStore';
import { TeamsDataContext } from '@components/context/TeamsContext';

export default function useThemesAPI() {
  const [loading, setLoading] = useState(false);

  const { currentTeam, ownTeam } = useContext(TeamsDataContext);

  const userDetailsId = currentTeam?.billing_account?.owner?.user_details_id;
  const ownerId = ownTeam?.billing_account?.owner?.user_details_id;

  const queryClient = useQueryClient();

  const createThemeApi = async (theme: Theme) => {
    setLoading(true);
    const res = await createUserTheme({ theme, userDetailsId, createdBy: ownerId });
    queryClient.invalidateQueries({
      queryKey: [GET_THEMES_KEY]
    });
    setLoading(false);
    return res;
  };

  const updateThemeApi = async (theme: Theme) => {
    setLoading(true);
    const res = await updateUserTheme({ theme, updatedBy: ownerId });
    queryClient.invalidateQueries({
      queryKey: [GET_THEMES_KEY]
    });
    setLoading(false);
    return res;
  };

  const deleteThemeApi = async (themeId: string, isDefaultTheme: boolean = false, darkThemeId?: string) => {
    setLoading(true);
    const res = await deleteUserTheme(themeId);
    if (isDefaultTheme && darkThemeId) {
      await setDefaultTheme({ themeId: darkThemeId, userDetailsId });
    }
    queryClient.invalidateQueries({
      queryKey: [GET_THEMES_KEY]
    });
    setLoading(false);
    return res;
  };

  const applyDefaultThemeApi = async (themeId: string) => {
    setLoading(true);
    const res = await setDefaultTheme({ themeId, userDetailsId });
    queryClient.invalidateQueries({
      queryKey: [GET_THEMES_KEY]
    });
    setLoading(false);
    return res;
  };

  const applySelectedThemeApi = async (themeId: string, templateId: string) => {
    setLoading(true);
    const res = await setSelectedTheme({ themeId, templateId });
    setLoading(false);
    return res;
  };

  return {
    createThemeApi,
    updateThemeApi,
    applyDefaultThemeApi,
    applySelectedThemeApi,
    deleteThemeApi,
    loading
  };
}
