import { useRef } from 'react';
import { type Interaction, type InteractionType } from '../types';

type SlideInsert = (
  index: number,
  slide: Interaction,
  newSlideType: InteractionType
) => Interaction | undefined;

type SlideHistory = () => {
  sync: SlideInsert;
  remove: (index: number) => void;
  flush: () => void;
};

type SlideStore = {
  [key in InteractionType]?: Interaction;
};

type StoreMap = Map<number, SlideStore>;

const useSlideHistory: SlideHistory = () => {
  const history = useRef<StoreMap>(new Map());
  const sync: SlideInsert = (index, slide, newSlideType) => {
    const storeItem: SlideStore = {
      ...history.current.get(index),
      [slide.type]: { ...slide }
    };
    history.current.set(index, storeItem);
    const existingInteraction = history.current.get(index)?.[newSlideType];
    if (existingInteraction) return existingInteraction;
  };

  const remove = (index: number) => {
    const newMap: StoreMap = new Map();
    history.current.delete(index);
    history.current.forEach((value, key) => {
      if (key > index) newMap.set(key - 1, { ...value });
      else newMap.set(key, { ...value });
    });
    history.current = newMap;
  };

  const flush = () => {
    history.current = new Map();
  };

  return {
    sync,
    remove,
    flush
  };
};

export default useSlideHistory;
